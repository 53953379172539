import { mapToPageHeader, Banner } from "@components/Banner"
import { Layout } from "@components/Layout"
import { mapToSEO, SEO } from "@components/SEO"
import {
  mapToKiwiVIPVisible,
  SignupBannerWrapper,
} from "@components/SignupBanner"
import { graphql } from "gatsby"
import React from "react"
import { GenericComponentsWrapper } from "@components/GenericComponentsWrapper/GenericComponentsWrapper"

interface Props {
  data: any
}

class MotorbikePage extends React.Component<Props> {
  render() {
    const elements = this.props.data?.allKontentItemGenericPageLayout?.nodes[0]?.elements
    if(elements === null || elements === undefined){
      return null
    }
    const { components } = elements
    const apiUrl = this.props.data.site.siteMetadata.apiUrl
    const metadata = mapToPageHeader(elements)
    const seoData = mapToSEO(elements)
    const kiwiVip = mapToKiwiVIPVisible(elements)

    return (
      <Layout version="standard">
        <SEO {...seoData} />
        <Banner {...metadata} isBiggerBanner={false} />
        <GenericComponentsWrapper linked_items={components.linked_items} apiUrl={apiUrl} reCaptchaSiteKey={this.props.data.site.siteMetadata.reCaptchaSiteKey} reCaptchaSiteKeyV2={this.props.data.site.siteMetadata.reCaptchaSiteKeyV2}
        emailValidationToken={this.props.data.site.siteMetadata.emailValidationToken} backgroundColor={'#f9f9f9'} darktheme={false}/> 
        {kiwiVip.visible && <SignupBannerWrapper version={kiwiVip.version} />}
      </Layout>
    )
  }
}

export default MotorbikePage
export const query = graphql`
{
  site {
    siteMetadata {
      apiUrl
      emailValidationToken
      reCaptchaSiteKey
      reCaptchaSiteKeyV2
    }
  }
  allKontentItemGenericPageLayout(filter: {elements: {general_page_content__title: {value: {eq: "Black Motorbike Plates"}}}}) {
    nodes {
      elements {
        seo__page_title {
          value
        }
        seo__page_description {
          value
        }
        seo__noindex {
          value {
            name
          }
        }
        seo__nofollow {
          value {
            name
          }
        }
        url {
          value
        }
        general_page_content__title {
          value
        }
        general_page_content__title_position {
          value {
            codename
          }
        }
        general_page_content__kiwivip_signup {
          value {
            name
            codename
          }
        }
        general_page_content__kiwivip_signup_version {
          value {
            name
            codename
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        components {
          itemCodenames
          linked_items {
            ... on KontentItemInfoImageBanner {
              elements {
                background_colors {
                  value {
                    codename
                  }
                }
                text_colors {
                  value {
                    codename
                  }
                }
                title {
                  value
                }
                description {
                  value
                }
                image {
                  value {
                    url
                    description
                  }
                }
                info_image_banner___image_position {
                  value {
                    codename
                  }
                }
              }
            }
            ... on KontentItemRestyleCombinationFinder {
              id
              elements {
                available_message {
                  value
                }
                button__button_color_theme {
                  value {
                    codename
                    name
                  }
                }
                button__button_type {
                  value {
                    codename
                    name
                  }
                }
                button__text {
                  value
                }
                button__url_slug {
                  value
                }
                heading {
                  value
                }
                restyle_guide_message {
                  value
                }
                restyle_options {
                  linked_items {
                    ... on KontentItemProductCategoryFormats {
                      id
                      elements {
                        base_price {
                          value
                        }
                        description {
                          value
                        }
                        example_image {
                          value {
                            url
                            description
                          }
                        }
                        format_type {
                          value {
                            name
                            codename
                          }
                        }
                        title {
                          value
                        }
                      }
                    }
                  }
                }
                sub_heading {
                  value
                }
                unavailable_message {
                  value
                }
                alternative_message{
                  value
                }
                campaign_items {
                  linked_items {
                    ... on KontentItemSweepstakeCampaign {
                      elements {
                        campaign_name {
                          value
                        }
                        expire_on {
                          name
                          type
                          value
                        }
                        valid_from {
                          name
                          type
                          value
                        }                        
                        transaction_type {
                          value {
                            name
                            codename
                          }
                        }                        
                        ads {
                          linked_items {
                            ... on KontentItemCampaignAds {
                              elements {
                                left_title_1 {
                                  value
                                }
                                left_title_2 {
                                  value
                                }
                                left_description {
                                  value
                                }
                                left_picture {
                                  value {
                                    name
                                    description
                                    url
                                    width
                                    height
                                  }
                                }
                                right_title {
                                  value
                                }
                                right_description_1 {
                                  value
                                }
                                right_description_2 {
                                  value
                                }
                                right_description_3 {
                                  value
                                }
                                left_background_colors {
                                  value {
                                    name
                                    codename
                                  }
                                }
                                right_background_colors {
                                  value {
                                    name
                                    codename
                                  }
                                }
                              }
                            }
                          }
                        }
                        popup {
                          linked_items {
                            ... on KontentItemCampaignPopup {
                              elements {
                                header {
                                  value
                                }
                                header_campaign_name{
                                  value
                                }
                                picture {
                                  value {
                                    name
                                    description
                                    url
                                    width
                                    height
                                  }
                                }                              
                                form_title {
                                  value
                                }
                                email_opt_in_text{
                                  value
                                }
                                footer {
                                  value
                                }
                                terms___conditions{
                                  value
                                }
                                background_color {
                                  value {
                                    name
                                    codename
                                  }
                                }
                                response {
                                  value
                                }
                                input_field_background_color {
                                  value {
                                    name
                                    codename
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on KontentItemFaqBlock {
              id
              elements {
                background_colors {
                  value {
                    codename
                    name
                  }
                }
                title {
                  value
                }
                faqs {
                  linked_items {
                    ... on KontentItemFaqItem {
                      id
                      elements {
                        answer {
                          value
                        }
                        faq_category {
                          value {
                            codename
                            name
                          }
                        }
                        question {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on KontentItemImageLinkBanner {
              elements {
                button__button_color_theme {
                  value {
                    codename
                    name
                  }
                }
                button__button_type {
                  value {
                    codename
                    name
                  }
                }
                button__text {
                  value
                }
                button__url_slug {
                  value
                }
                image {
                  value {
                    url
                    description
                  }
                }
                title {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
}

`
